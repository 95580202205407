import * as React from "react"
import Layout from "../components/layout"
import { ReactComponent as LinkedInIcon } from "../images/linkedin.svg";
import { ReactComponent as TwitterIcon } from "../images/twitter.svg";
import { ReactComponent as InstagramIcon } from "../images/instagram.svg";
import { ReactComponent as YoutubeIcon } from "../images/youtube.svg";
import { ReactComponent as GithubIcon } from "../images/github.svg";
import { ReactComponent as OpenSeaIcon } from "../images/opensea.svg";
import { ReactComponent as DribbbleIcon } from "../images/dribbble.svg";
import SocialLink from "../components/SocialLink";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import SunAltImg from "../images/sun.png";
import { Keywords } from "../components/constants";
import Sun from "../components/sun";

// data
const socialLinks = [
  {
    name: "LinkedIn",
    url: "https://linkedin.com/in/mickaelbonfill",
    icon: LinkedInIcon,
  },
  {
    name: "Twitter",
    url: "https://twitter.com/jbltx",
    icon: TwitterIcon,
  },
  {
    name: "Instagram",
    url: "https://instagram.com/mickael.bonfill",
    icon: InstagramIcon,
  },
  {
    name: "Youtube",
    url: "https://youtube.com/mickaelbonfill",
    icon: YoutubeIcon,
  },
  {
    name: "Github",
    url: "https://github.com/jbltx",
    icon: GithubIcon,
  },
  {
    name: "Opensea",
    url: "https://opensea.io/jbltx",
    icon: OpenSeaIcon,
  },
  {
    name: "Dribbble",
    url: "https://dribbble.com/jbltx",
    icon: DribbbleIcon,
  },
]

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
      < title>JBLTX</title>
        <meta name="author" content="Mickael Bonfill" />
        <meta name="description" content="Personal Website - Mickael Bonfill aka JBLTX" />
        <meta name="keywords" content={Keywords.join(", ")} />
      </Helmet>
      <div className="homepage-profile-wrapper">
        <div className="homepage-profile">
          <Sun width="256" height="256" id="sun">
            <img width="256" height="256" src={SunAltImg} alt="jbltx logo" />
          </Sun>
          <h1>Software Engineer &amp; Digital Artist</h1>
          <div className="social-links">
            { socialLinks.map((sl, i) => <SocialLink href={sl.url} title={sl.name} icon={sl.icon} key={i}/>) }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Keywords = [
  "Mickael",
  "Bonfill",
  "jbltx",
  "about",
  "profile",
  "bio",
  "personal",
  "portfolio",
  "digital art",
  "art",
  "street art",
  "digital painting",
  "tablet",
  "wacom",
  "intuos",
  "shadertoy",
  "code",
  "coding",
  "creative coding",
  "opensea",
  "rarible",
  "nft",
  "crypto",
  "token",
  "collection",
  "artist",
  "museum",
  "digital",
  "metaverse",
  "bayc",
  "mayc",
  "doodles",
  "cool cats",
  "bitcoin",
  "eth",
  "btc",
  "ethereum",
  "blockchain",
  "pfp",
  "cryptopunks",
  "foundation",
  "zora",
  "niftygateway",
  "fiverr",
  "Inigo Quilez",
  "iq",
  "raymarching",
  "sdf",
  "shader",
  "glsl",
  "webgl",
  "generative art",
  "pattern",
  "voronoi",
  "metaballs",
  "lava lamp",
  "eighties",
  "stranger things",
  "youtube",
  "github",
  "unity",
  "unity3d",
  "ue4",
  "unreal engine",
  "epic games",
  "3d",
  "2d"
];
